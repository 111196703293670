<template>
  <div class="page">
    <Login ref="login" :callback="init" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="请输入搜索关键词"
            ref="keyword"
            show-action
            ><template #action>
              <div @click="search">搜索</div>
            </template></van-search
          >
        </van-col>
      </van-row>
    </div>
    <Sift :callback="siftCondition" ref="sift" />
    <van-list
      :finished="finished"
      finished-text="没有更多了"
      offset="0"
      class="task-list"
      :immediate-check="false"
      @load="retrieveTaskList"
    >
      <template #default>
        <van-row
          class="task bdc-b1s-gray"
          v-for="task in taskList"
          :key="task.code"
          @click="handle(task.code)"
        >
          <van-col span="24" class="content">
            <van-row>
              <van-col span="24" class="name text-short">{{
                task.name
              }}</van-col>
            </van-row>
            <van-row>
              <van-col span="17" class="label">
                <span
                  v-for="item in task.label"
                  :key="item.name"
                  class="item bgc-gray"
                  >{{ item.name }}</span
                >
              </van-col>
              <van-col span="7" class="salary fc-cyan"
                ><span v-if="task.salaryMin > 1000"
                  >{{ (task.salaryMin / 1000).toFixed(1) }}K-{{
                    (task.salaryMax / 1000).toFixed(1)
                  }}K</span
                ><span v-if="task.salaryMin < 1000"
                  >{{ task.salaryMin }}-{{ task.salaryMax }}</span
                >/<span v-if="task.salaryType === 'YER'">年</span>
                <span v-if="task.salaryType === 'MOT'">月</span>
                <span v-if="task.salaryType === 'DAY'">日</span></van-col
              >
            </van-row>
            <van-row>
              <van-col span="17" class="time"
                >日期：{{ task.timeStart }}到{{ task.timeEnd }}</van-col
              >
              <van-col span="7" class="amount"
                >人数:{{ task.amount }}人</van-col
              >
            </van-row>
          </van-col>
        </van-row>
        <van-row v-if="taskList.length <= 0">
          <van-col span="24" class="no-record">
            <van-image
              width="103"
              height="103"
              :src="require('../../../../assets/images/home/no-record.png')"
            ></van-image>
          </van-col>
        </van-row>
      </template>
    </van-list>
    <Clue ref="clue" />
    <Loading :show="loadingShow" />
    <LogVisit
      ref="logVisit"
      module-type="RCT"
      module-label="RCT_SEARCH_INDEX"
    />
    <Share ref="share" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
