import { Search, DropdownMenu, DropdownItem, Image, Tag, NavBar, Popup, List, Icon } from 'vant'
import Loading from '../../common/Loading.vue'
import Sift from '../common/Sift.vue'
import Share from '../../common/Share.vue'
import Login from '../../intercept/Login.vue'
import LogVisit from '../../common/LogVisit.vue'
import Clue from '../../common/Clue.vue'
export default {
    components: {
        Loading: Loading,
        Sift: Sift,
        Share: Share,
        Login: Login,
        LogVisit: LogVisit,
        Clue: Clue,
        [Search.name]: Search,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Image.name]: Image,
        [Tag.name]: Tag,
        [NavBar.name]: NavBar,
        [Popup.name]: Popup,
        [List.name]: List,
        [Icon.name]: Icon
    },
    data () {
        return {
            finished: false,
            loadingShow: false,
            keyword: '',
            classifyOptions: [{ text: '课程分类', value: '' }],
            classifyCode: '',
            condition: { classify: '', instIndustry: '', instNature: '', instType: '', instScale: '', type: '' },
            page: { current: 0, size: 10, last: 0 },
            taskList: []
        }
    },
    mounted () {
        document.title = '职位信息'
        var query = this.$route.query
        if (query.keyword !== undefined && query.keyword !== null) {
            this.keyword = query.keyword
        }
        if (query.classifyCode !== undefined && query.classifyCode !== null) {
            this.condition.classify = query.classifyCode
        }
        this.$refs.login.validate()
    },
    methods: {
        init () {
            this.$refs.logVisit.createLog()
            this.initCondition()
            this.retrieveTaskList()
            this.initShare()
        },
        initShare () {
            var title = '好工作不求人，就上微职客'
            var desc = '国企、央企和事业单位等多种类型的多个职位任您选择'
            var link = 'https://moc.utopanet.com/recruit/search?keyword=' + this.keyword
            var logo = 'https://moc.utopanet.com/static/logo.png'
            this.$refs.share.initParam(title, desc, link, logo)
        },
        initCondition () {
            var condition = { classify: this.condition.classify }
            this.$refs.sift.initCondition(condition)
        },
        search () {
            this.taskList = []
            this.retrieveTaskList()
            this.createSearchClue()
        },
        handle (code) {
            window.sessionStorage.setItem(this.RECRUIT_CONSULT_INDEX_BACK, window.location.href)
            var role = window.sessionStorage.getItem(this.SESSION_ROLE)
            if (role === 'SEL') {
                this.$router.push({ path: '/mde/recruit/detail', query: { taskCode: code } })
            } else {
                this.$router.push({ path: '/mde/recruit/consult', query: { taskCode: code } })
            }
        },
        siftCondition (condition) {
            this.taskList = []
            this.condition.instIndustry = condition.instIndustry
            this.condition.classify = condition.classify
            this.condition.region = condition.region
            this.condition.instNature = condition.instNature
            this.condition.instType = condition.instType
            this.condition.instScale = condition.instScale
            this.condition.type = condition.type
            this.page.current = 0
            this.page.last = 0
            this.finished = false
            this.retrieveTaskList()
        },
        async retrieveTaskList () {
            if (!this.finished) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
                var pd = {
                    keyword: this.keyword,
                    classifyCode: this.condition.classify,
                    operatorCode: operator,
                    regionCode: this.condition.region,
                    jobCode: this.condition.job,
                    instIndustry: this.condition.instIndustry,
                    instNature: this.condition.instNature,
                    instType: this.condition.instType,
                    instScale: this.condition.instScale,
                    type: this.condition.type,
                    current: this.page.current,
                    size: this.page.size
                }
                var { data: res } = await this.$http.post(this.BMS_URL + '/recruit/taskAPC/retrieveTaskList', this.$qs.stringify(pd))
                if (res.status === '200') {
                    this.taskList = this.taskList.concat(res.data)
                    this.page.last = res.page.last
                    if (res.data.length <= 0) {
                        this.finished = true
                    }
                }
                this.loadingShow = false
            }
        },
        createSearchClue () {
            var date = new Date()
            var dt = date.getTime()
            var param = { keyword: this.keyword, url: window.location.href }
            var content = '补岗招聘搜索'
            this.$refs.clue.createClue('RECRUIT_SEARCH_RECRUIT', dt, JSON.stringify(param), content)
        },
        back () {
            var role = window.sessionStorage.getItem(this.SESSION_ROLE)
            if (role === 'SEL') {
                this.$router.push({ path: '/mde/seller/home/recruit' })
            } else {
                this.$router.push({ path: '/mde/recruit/home' })
            }
        }

    }
}
